// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-js": () => import("/home/igepa/www/src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-basic-page-js": () => import("/home/igepa/www/src/templates/basicPage.js" /* webpackChunkName: "component---src-templates-basic-page-js" */),
  "component---src-pages-404-js": () => import("/home/igepa/www/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/home/igepa/www/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nakupni-kosik-index-js": () => import("/home/igepa/www/src/pages/nakupni-kosik/index.js" /* webpackChunkName: "component---src-pages-nakupni-kosik-index-js" */),
  "component---src-pages-nakupni-kosik-adresa-js": () => import("/home/igepa/www/src/pages/nakupni-kosik/adresa.js" /* webpackChunkName: "component---src-pages-nakupni-kosik-adresa-js" */),
  "component---src-pages-nakupni-kosik-odeslat-js": () => import("/home/igepa/www/src/pages/nakupni-kosik/odeslat.js" /* webpackChunkName: "component---src-pages-nakupni-kosik-odeslat-js" */),
  "component---src-pages-ochrana-osobnich-udaju-js": () => import("/home/igepa/www/src/pages/ochrana-osobnich-udaju.js" /* webpackChunkName: "component---src-pages-ochrana-osobnich-udaju-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/igepa/www/.cache/data.json")

